import React from 'react'

import fb from './images/fb.png'
import twitter from './images/twitter.png'
import Linkedin from './images/linkedin.jpeg'
import youtube from './images/youtube.png'
import insta from './images/insta.png'
import pintrest from './images/pintrest.png'
import home2 from './img/home2.png'
import home1 from './img/home1.png'
import home3 from './img/home3.png'
import img1 from './img/home-img1.jpg'
import icon14 from './assets/images/icon/icon-14.png'
import icon13 from './assets/images/icon/icon-13.png'
import icon12 from './assets/images/icon/icon-12.png'
import gallery1 from './assets/images/gallery/gallery-1.jpg'
import gallery2 from './assets/images/gallery/gallery-2.jpg'
import gallery3 from './assets/images/gallery/gallery-3.jpg'
import gallery4 from './assets/images/gallery/gallery-4.jpg'
import gallery5 from './assets/images/gallery/gallery-5.jpg'
import gallery6 from './assets/images/gallery/gallery-6.jpg'
import gallery9 from './assets/images/gallery/gallery-11.jpg'


import akoda1 from './assets/images/gallery/akoda-1.jpeg'
import akoda2 from './assets/images/gallery/akoda2.jpeg'
import akoda3 from './assets/images/gallery/akoda3.jpeg'
import akoda4 from './assets/images/gallery/akoda4.jpeg'
import akoda13 from './assets/images/gallery/akoda13.jpeg'
import akoda15 from './assets/images/gallery/akoda15.jpeg'



import gallery7 from './assets/images/gallery/gallery-7.jpg'
import story1 from './images/resource/story1.png'
import story2 from './images/resource/story2.png'
import story3 from './images/resource/story3.png'
import story4 from './images/resource/story4.png'
import TwoSlides from './TwoSlides';
import Slider from 'react-slick';
import SocialMedia from './SocialMedia';
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet';
import OurEvents from './OurEvents';

const Home = () => {

	{ window.scrollTo({ top: 0, behavior: 'smooth' }); };
	var bannerImg = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		fade: true,
		dots: false,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};
	var storiesCarousel = {
		slidesToShow: 2,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		dots: true,

		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};
	var gallarySlider = {
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		autoplay: false,
		infinite: true,

		dots: false,
		autoplaySpeed: 3000,

		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					autoplay: false,
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					autoplay: true,
					dots: false,
					arrows: false
				}
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					dots: false,
					autoplay: true,
					arrows: false
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					autoplay: true,
				}
			}
		]
	};


	return (
		<div>
			<Helmet>
				<title> Akoda SK foundation | Akoda fundraisers |Crowdfunding | Fundraising | Best Crowdfunding Platform | Hyderabad | India </title>
				<meta name="description" content="Just share this fundraising event with friends and family. Support will start soon." />
				<meta name="keywords" content="Akoda sk foundation ,Akoda, Crowdfunding, Fundraising, Best Crowdfunding Platform in Hyderabad ,medical fundraising ,the best online fundraising platform in Hyderabad telangana , best fundraising platform,best fundraising platform for individuals ,best fundraising platform for nonprofits ,best fundraising platform for charities ,best fundraising platform for donations ,best fundraising platform in Hyderabad ,best charity in Hyderabad,  ngo in telangana ,best fundraising platform in telangana , best fundraising platform Hyderabad telangana , social service platform in Hyderabad telangana , donation platform in Hyderabad telangana , Hyderabad,  India."
				/>
			</Helmet>

			{/* <!-- Floating Social Media bar Starts --> */}

			<div className="float-sm">
				<div className="fl-fl float-fb">
					<img src={fb} height="40px" width="35px" alt="" />
					{/* <!-- <i className="fa fa-facebook"></i> --> */}
					<a href="https://www.facebook.com/akodafundraisers/?ref=pages_you_manage" target="_blank"> Like us</a>
				</div>
				<div className="fl-fl float-tw">
					<img src={twitter} height="40px" width="35px" alt="" />
					{/* <!-- <i className="fa fa-twitter"></i> --> */}
					<a href="https://twitter.com/AkodaFundraise1" target="_blank">Follow us</a>
				</div>
				<div className="fl-fl float-gp">
					{/* <!-- <i className="fa fa-google-plus"></i> --> */}
					{/* <i className="fa-brands fa-linkedin-in" height="40px" width="35px" ></i> */}
					<img src={Linkedin} height="40px" width="35px" alt="" />
					<a href="https://www.linkedin.com/in/akoda-fundraisers-867181234/" target="_blank">Follow Us</a>
				</div>
				<div className="fl-fl float-rs">
					<img src={youtube} height="40px" width="35px" alt="" />
					{/* <!-- <i className="fa fa-youtube"></i> --> */}
					<a href="https://youtube.com/channel/UCb2lNV3dVv6vBin7wDQLrIQ" target="_blank">Follow Us</a>
				</div>
				<div className="fl-fl float-ig">
					<img src={insta} height="40px" width="35px" alt="" />
					{/* <!-- <i className="fa fa-instagram"></i> --> */}
					<a href="https://www.instagram.com/akodafundraisers/" target="_blank">Follow us</a>
				</div>
				<div className="fl-fl float-pn">
					<img src={pintrest} height="40px" width="35px" alt="" />
					{/* <!-- <i className="fa fa-pinterest"></i> --> */}
					<a href="https://pin.it/2DFcMQy" target="_blank">Follow us!</a>
				</div>
			</div>
			{/* <!-- Start Banner Area --> */}
			<section className="row obu banner-area ptb-100 line-img" >

				<div className="col col-md-6 col-sm-6 col-xs-6 d-table ">
					<div className="d-table-cell">
						<div className="container">
							<div className="banner-content">
								<span className="top-title">WHAT'S PROCESS</span>
								<h1>Medical Emergencies or Social Causes? <span style={{ color: '#0286CD' }}> We can help!
								</span> </h1>
								<p>AkoDa’s 0% Platform fees ensure maximum funds for you</p>

								<div className="banner-btn">

									<Link to="/volunteers-application" className="default-btn border-radius-btn  glowing">Be A Volunteer</Link>
									<Link to="/donate-now" className="default-btn border-radius-btn">
										Start a Fundraiser
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className=" col col-md-6 col-sm-6 col-xs-6 banner-img-2" id="mobi-img-hide">
					<div className="stories-car banner-imgs">
						<Slider {...bannerImg}>
							<img src={home2} alt="" />
							<img src={home1} alt="" />
							<img src={home3} alt="" />
						</Slider>
					</div>
					{/* <!-- Stories Carousel --> */}

				</div>
			</section>

			{/* <ScriptTag isHydrating={true} type="text/javascript"  src="./assets/js/slick.min.js" /> */}

			<section className="blog-post-area " style={{ marginTop: '30px' }}>
				<div className="container">
					<div className="row ">

						<div className="col col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ padding: '0px' }}>
							<div className="text-right">
								<img alt="" src={img1} className="adds-size"
									style={{ width: '151px', height: '151px', borderRadius: '10px 0px 0px 10px' }} />
							</div>
						</div>
						<div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 height-bb" style={{ background: '#f5f4f4' }}>
							<div className="section-title section-title-left height-rr">

								<h2 style={{ paddingTop: '10px' }}>Make a monthly donation to help girls get an education</h2>
								<p style={{ textAlign: 'justify' }}>Your support will ensure that countless children receive education, health care, and a happy life..</p>
							</div>
						</div>

						<div className="col col-lg-3 col-md-3 col-sm-3 col-xs-3 height-bbb"
							style={{ background: '#f5f4f4', padding: '55px', borderRadius: '0px 10px 10px 0px', borderRightColor: '#F08821', borderRightStyle: 'solid' }}>
							<div className="text-left">
								<Link to="/donate-now" className="default-btn border-btn text-center height-rrr"> Donate Now</Link>
							</div>
						</div>

					</div>
				</div>
			</section>
			{/* <OurCauses /> */}
			<OurEvents />

			<TwoSlides />

			<section className="volunteers-area volunteers-areaone  pb-100" >
				<div className="fluid-container">
					<div className="volunteers-bg">
						<div className="row">
							


							<div className="container help-content">
								<h2 id="white-clr"> Begin A Fundraiser In Three Soft Ways </h2>
								<div className="row justify-content-center" style={{textAlign:'center', alignItems:'center'}}>
									<div className="col-md-4 col-sm-6">
										<div className="single-help"    >
											<img src={icon12} alt="Image" />
											<h4 id="white-clr"> Begin your fundraiser </h4>
											<p id="white-clr">It takes 2 minutes. Give us some details about you and the
												people you raise funds for.</p>
										</div>
									</div>

									<div className="col-md-4 col-sm-6">
										<div className="single-help">
											<img src={icon13} alt="Image" />
											<h4 id="white-clr"> participate your fundraiser </h4>
											<p id="white-clr">participate your fundraiser Just share this fundraising
												event with friends and family. Support will start soon.</p>
										</div>
									</div>

									<div className="col-md-4 col-sm-6">
										<div className="single-help">
											<img src={icon14} alt="Image" />
											<h4 id="white-clr"> Take out resources </h4>
											<p id="white-clr">The collected funds can be easily withdrawn directly to
												your bank account.</p>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="block block-mobi" style={{    backgroundColor: '#000000fe',paddingTop: '50px'}}>
					<div className="container" >
						<div className="row">
							<div className="col-lg-6 col-md-12">
								<div className="shelter-text">
									<span> For a Women Empowerment?</span>
									<h3>Girl’s Education  <span style={{ color: 'rgb(69 159 109)' }}>Women empowerment</span></h3>
									<p>Someway or the other, through experience of self or other, we have, most of us, come across the truth of the proverb, “If you educate a man, you educate an individual. But, if you educate a woman, you educate a nation”. Women education is important not only for women empowerment but also has social and cultural benefits, such as, lower risk of domestic violence, lower newborn mortality rates, quality workforce, etc.</p>
									<p>When you buy an extra pen for your own daughter, imagine how many daughters of the nation are serving child labors when they actually deserve going to school. You give her some money, which might be snatched away from her, but you contribute towards educating her, none can take that away. Take a step towards it and we assure a giant leap as far as the cause is concerned.</p>
									<Link to="/donate-now" className="default-btn border-radius-btn" style={{color:'#fff'}}>
										Start a Fundraiser
									</Link>
								</div>
							</div>

							<div className="col-lg-6 col-md-12">

								<div className="stories-carousel">
									<Slider {...storiesCarousel}>
										<div className="story-slide story-slide-img">
											<div className="story">
												<img src={story1} alt="" />
												<h3><a href="#" title="" style={{ color: '#000' }}> Women Empowerment</a></h3>
											</div>
										</div>

										<div className="story-slide story-slide-img">
											<div className="story">
												<img src={story2} alt="" />
												<h3><a href="#" title="" style={{ color: '#000' }}>Women Education</a></h3>
											</div>
										</div>

										<div className="story-slide story-slide-img">
											<div className="story">
												<img src={story3} alt="" />
												<h3><a href="#" title="" style={{ color: '#000' }}>Women Empowerment</a></h3>
											</div>
										</div>

										<div className="story-slide story-slide-img">
											<div className="story">
												<img src={story4} alt="" />
												<h3><a href="#" title="" style={{ color: '#000' }}>Women  Education</a></h3>
											</div>
										</div>
									</Slider>
								</div>
								{/* <!-- Stories Carousel --> */}
							</div>
						</div>
					</div>
				</div>
			</section>


			{/* <Volunteer /> */}

			<section id="gallery" className="mb-gallery">
				<div >
					<div id="image-gallery">
						<div className="section-title">
							<span className="top-title">OUR ACTIVITIES</span>
							<h2>Sample images of some recent work are highlighted</h2>
						</div>
						<div class="row ">
						<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda15}><img src={akoda15} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div><div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda2}><img src={akoda2} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div><div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda13}><img src={akoda13} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div><div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda3}><img src={akoda3} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div><div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={akoda1}><img src={akoda1} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={gallery1}><img src={gallery1} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery2}><img src={gallery2} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery3}><img src={gallery3} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery4}><img src={gallery4} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery5}><img src={gallery5} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery6}><img src={gallery6} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={gallery7}><img src={gallery7} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							


						</div>
					</div>
				</div>

			</section>
			<section className="gallery-area gallery-popup pt-100 pb-70 gallery-bs">
				<div className="container">
					<div className="section-title">
						<span className="top-title">OUR ACTIVITIES</span>
						<h2>Sample images of some recent work are highlighted</h2>
					</div>
					<div className="row gallary-slider">
						<Slider {...gallarySlider}>
						
							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={akoda13} alt="Image" />
								</div>
							</div>
							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={akoda1} alt="Image" />
								</div>
							</div><div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={akoda15} alt="Image" />
								</div>
							</div><div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={akoda2} alt="Image" />
								</div>
							</div><div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={akoda3} alt="Image" />
								</div>
							</div>
							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={akoda4} alt="Image" />
								</div>
							</div>


							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={gallery1} alt="Image" />
								</div>
							</div>
							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={gallery2} alt="Image" />
								</div>
							</div>
							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={gallery5} alt="Image" />
								</div>
							</div>
							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={gallery6} alt="Image" />
								</div>
							</div>
							
							<div className="col-lg-4 gallery-img">
								<div className="gallery-img">
									<img src={gallery3} alt="Image" />
								</div>
							</div>

						</Slider>
					</div>
				</div>
			</section>
		

			<SocialMedia />
			{/* <Partners /> */}

	

		</div >

	)
}

export default Home