import React, { useState } from 'react';

const VolunteersApplication = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    age: '',
    gender: '',
    email: '',
    guardianName: '',
    flatNo: '',
    landmark: '',
    village: '',
    district: '',
    state: '',
    city: '',
    pinCode: '',
    category: '',
    documentType: '',
    documentFile: null,
    volunteerCV: null,
    volunteerImage: null,
    areasOfInterest: '',
    availability: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  return (
    <div className="main-contact-area ptb-100 ">
      <div className="container">
        <div className="row">
          <h3 style={{ paddingTop: '30px' }}>Applicant Details</h3>
          <div className="col-lg-12 view-form">
            <form className="form-group tp" id="myForm">
              <div className="row">
                <div className="col-md-4">
                  <label>Your Name</label>
                  <input type="text" name="name" className="form-control fp" onChange={handleChange} value={formData.name} required />
                </div>
                <div className="col-md-4">
                  <label>Phone Number</label>
                  <input type="text" name="contact" className="form-control fp" onChange={handleChange} value={formData.contact} required />
                </div>
                <div className="col-md-4">
                  <label>Age</label>
                  <input type="number" name="age" className="form-control fp" onChange={handleChange} value={formData.age} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Gender</label>
                  <select name="gender" className="form-control fp" onChange={handleChange} value={formData.gender} required>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label>Email</label>
                  <input type="email" name="email" className="form-control fp" onChange={handleChange} value={formData.email} required />
                </div>
                <div className="col-md-4">
                  <label>Father / Guardian Name</label>
                  <input type="text" name="guardianName" className="form-control fp" onChange={handleChange} value={formData.guardianName} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Upload Volunteer CV</label>
                  <input type="file" name="volunteerCV" className="form-control fp" onChange={handleFileChange} required />
                </div>
                <div className="col-md-4">
                  <label style={{fontSize:'13px'}} >Upload  Documents (ID proof, Inter/ Any Degree Certificate, etc.)</label>
                  <input type="file" name="documentFile" className="form-control fp" onChange={handleFileChange} required />
                </div>
                <div className="col-md-4">
                  <label>Upload Volunteer Image</label>
                  <input type="file" name="volunteerImage" className="form-control fp" onChange={handleFileChange} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Areas of Interest</label>
                  <select name="areasOfInterest" className="form-control fp" onChange={handleChange} value={formData.areasOfInterest} required>
                    <option value="">Select Interest</option>
                    <option value="Environmental Conservation">Environmental Conservation</option>
                    <option value="Teaching & Education">Teaching & Education</option>
                    <option value="Health & Medical Assistance">Health & Medical Assistance</option>
                    <option value="Animal Welfare">Animal Welfare</option>
                    <option value="Community Development">Community Development</option>
                    <option value="Fundraising & Donations">Fundraising & Donations</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label>Availability</label>
                  <select name="availability" className="form-control fp" onChange={handleChange} value={formData.availability} required>
                    <option value="">Select Availability</option>
                    <option value="Weekend">Weekend</option>
                    <option value="Weekdays">Weekdays</option>
                    <option value="Both">Both</option>
                    <option value="Flexible">Flexible</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label>Message</label>
                  <textarea name="message" className="form-control fp" rows="4" onChange={handleChange} value={formData.message} required placeholder="Describe your request in detail..."></textarea>
                </div>
              </div>
              <button type="submit" className="default-btn tp">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteersApplication;
