import React, { useState } from "react";

const HelpSeekerForm = () => {
  { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    contact: "",
    flatNo: "",
    landmark: "",
    village: "",
    district: "",
    state: "",
    city: "",
    pinCode: "",
    category: "",
    documentType: "",
    documentFile: null,
    schoolName: "",
    studentClass: "",
    schoolAddress: "",
    reason: "",
    message: "",
    email: "",
  });

  const categoryDocuments = {
    Education: ["Aadhar", "PAN", "Education Certificate"],
    Children: ["Aadhar", "PAN", "Birth Certificate"],
    Medical: ["Aadhar", "PAN", "Medical Report"],
    Orphan: ["Aadhar", "PAN", "Orphan Certificate"],
    Other: ["Aadhar", "PAN", "Orphan Certificate"],
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, documentFile: e.target.files[0] });
  };

  return (
    <div className="main-contact-area ptb-100 ">
      <div className="container">
        <div className="row">
          <h3 style={{paddingTop:'30px'}}>Applicant Details</h3>
          <div className="col-lg-12 view-form">
            <form className="form-group tp" id="myForm">
              <div className="row">
                <div className="col-md-4">
                  <label>Your Name</label>
                  <input type="text" name="name" className="form-control fp" onChange={handleChange} value={formData.name} required />
                </div>
                <div className="col-md-4">
                  <label>Phone Number</label>
                  <input type="text" name="contact" className="form-control fp" onChange={handleChange} value={formData.contact} required />
                </div>
                <div className="col-md-4">
                  <label>Age</label>
                  <input type="number" name="age" className="form-control fp" onChange={handleChange} value={formData.age} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Gender</label>
                  <select name="gender" className="form-control fp" onChange={handleChange} value={formData.gender} required>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label>Email</label>
                  <input type="email" name="email" className="form-control fp" onChange={handleChange} value={formData.email} required />
                </div>
                <div className="col-md-4">
                  <label>Father / Guardian name</label>
                  <input type="email" name="email" className="form-control fp" onChange={handleChange} value={formData.email} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Flat No/ H.NO</label>
                  <input type="text" name="flatNo" className="form-control fp" onChange={handleChange} value={formData.flatNo} required />
                </div>
                <div className="col-md-4">
                  <label>Landmark / Area</label>
                  <input type="text" name="landmark" className="form-control fp" onChange={handleChange} value={formData.landmark} required />
                </div>
                <div className="col-md-4">
                  <label>Village / Mandal</label>
                  <input type="text" name="village" className="form-control fp" onChange={handleChange} value={formData.village} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>District</label>
                  <input type="text" name="district" className="form-control fp" onChange={handleChange} value={formData.district} required />
                </div>
                <div className="col-md-4">
                  <label>State</label>
                  <input type="text" name="state" className="form-control fp" onChange={handleChange} value={formData.state} required />
                </div>
                <div className="col-md-4">
                  <label>City</label>
                  <input type="text" name="city" className="form-control fp" onChange={handleChange} value={formData.city} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Pin Code</label>
                  <input type="text" name="pinCode" className="form-control fp" onChange={handleChange} value={formData.pinCode} required />
                </div>
                <div className="col-md-4">
                  <label>Category</label>
                  <select name="category" className="form-control fp" onChange={handleChange} value={formData.category} required>
                    <option value="">Select Category</option>
                    {Object.keys(categoryDocuments).map((category) => (
                      <option key={category} value={category}>{category}</option>
                    ))}
                  </select>
                </div>
                {formData.category && (
                     <div className="row">
                  <div className="col-md-4">
                    <label>Document Type</label>
                    <select name="documentType" className="form-control fp" onChange={handleChange} value={formData.documentType} required>
                      <option value="">Select Document</option>
                      {categoryDocuments[formData.category].map((doc) => (
                        <option key={doc} value={doc}>{doc}</option>
                      ))}
                    </select>
                  </div>
                  
                   <div className="col-md-4">
                     <label>Upload Document</label>
                     <input type="file" name="documentFile" className="form-control fp" onChange={handleFileChange} required />
                   </div>
                   <div className="col-md-4">
                     <label>Upload student Photo</label>
                     <input type="file" name="documentFile" className="form-control fp" onChange={handleFileChange} required />
                   </div>
                 </div>
                )}
              </div>
             
              {formData.category === "Education" && (
                <div className="row">
                  <div className="col-md-4">
                    <label>School Name</label>
                    <input type="text" name="schoolName" className="form-control fp" onChange={handleChange} value={formData.schoolName} required />
                  </div>
                  <div className="col-md-4">
                    <label>Student Class</label>
                    <input type="text" name="studentClass" className="form-control fp" onChange={handleChange} value={formData.studentClass} required />
                  </div>
                  <div className="col-md-4">
                    <label>School Address</label>
                    <input type="text" name="schoolAddress" className="form-control fp" onChange={handleChange} value={formData.schoolAddress} required />
                  </div>
                  <div className="row">
                <div className="col-md-4">
                  <label>Reason for Application</label>
                  <select name="reason" className="form-control fp" onChange={handleChange} value={formData.reason} required>
                    <option value="">Select Reason</option>
                    <option value="Financial Assistance">Seeking Financial Assistance</option>
                    <option value="Medical Help">Need Medical Help</option>
                    <option value="Educational Support">Requesting Educational Support</option>
                    <option value="Shelter/Home">Looking for Shelter/Home</option>
                    <option value="Legal Assistance">Need Legal Assistance</option>
                    <option value="Food and Clothing Aid">Seeking Food and Clothing Aid</option>
                    <option value="Other">Other (Specify in Message)</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label>Message</label>
                  <textarea name="message" className="form-control fp" rows="4" onChange={handleChange} value={formData.message} required placeholder="Describe your request in detail..."></textarea>
                </div>
              </div>
                </div>
              )}
              
              <button type="submit" className="default-btn tp">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSeekerForm;
