
import './App.css';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Home from './Component/Home.js';
import AboutUs from './Component/AboutUs';
import Fundraisers from './Component/Fundraisers';
import Crowdfunding from './Component/Crowdfunding';
// import SupportOurVillage from './Component/SupportOurVillage';
import Gallery from './Component/Gallery';
import ContactUs from './Component/ContactUs';
// import Events from './Component/Events';
import DonateNowForm from './Component/DonateNowForm';
import BeVolunteer from './Component/BeVolunteer';
import TermsAndConditions from './Component/TermsAndConditions';
// import RefundAndCancellationPolicy from './Component/RefundAndCancellationPolicy';
import PrivacyPolicy from './Component/PrivacyPolicy';
// import EventsEight from './Component/EventsEight';
 import EventsOne from './Component/EventsOne';
 import EventsTwo from './Component/EventsTwo';
 import EventsThree from './Component/EventsThree';
 import EventsFour from './Component/EventsFour';
 import EventsFive from './Component/EventsFive';
 import EventsSix from './Component/EventsSix';
 import EventsSeven from './Component/EventsSeven';
 import EventsEight from './Component/EventsEight';
 import EventsNine from './Component/EventsNine';
 import EventsTen from './Component/EventsTen';
 import EventsEleven from './Component/EventsEleven';
 import EventsTwelve from './Component/EventsTwelve';
 import EventsThirteen from './Component/EventsThirteen';
 import EventsFourteen from './Component/EventsFourteen';
 import EventsFifteen from './Component/EventsFifteen';
 import EventsSixteen from './Component/EventsSixteen';
 import EventsSeventeen from './Component/EventsSeventeen';
import { Helmet } from 'react-helmet';
import Careers from './Component/Careers';
import FoodDonationEvent from './Component/FoodDonationEvent';
import MedicalKitEvent from './Component/MedicalKitEvent';
import Nav from './Component/Nav';
import Footer from './Component/Footer';
import Subscribe from './Component/Subscribe';
import SignIn from './Component/SignIn';
import AkodaEventOne from './Component/AkodaEventOne.js';
import Oldagefoodevent from './Component/Oldagefoodevent.js';
import HelpSeekerForm from './Component/HelpSeekerForm.js';
import VolunteersApplication from './Component/VolunteersApplication.js';

<Helmet>

<title> Akoda SK foundation | Akoda fundraisers |Crowdfunding | Fundraising | Best Crowdfunding Platform | Hyderabad | India</title>
<meta name="description" content=" Just share this fundraising event with friends and family. Support will start soon." />
<meta name="keywords"    content="Akoda sk foundation ,Akoda, Crowdfunding, Fundraising, Best Crowdfunding Platform in Hyderabad ,medical fundraising ,the best online fundraising platform in Hyderabad telangana , best fundraising platform,best fundraising platform for individuals ,best fundraising platform for nonprofits ,best fundraising platform for charities ,best fundraising platform for donations ,best fundraising platform in Hyderabad ,best charity in Hyderabad,  ngo in telangana ,best fundraising platform in telangana , best fundraising platform Hyderabad telangana , social service platform in Hyderabad telangana , donation platform in Hyderabad telangana , Hyderabad,  India."
        />
</Helmet>

function App() {
  return (
    
    <Router>
      <Nav/>
    <Routes>  
      
    <Route path="/" element={<Home/>}/>
    <Route path="/about-us" element={<AboutUs/>}/>
    <Route path="/fundraisers" element={<Fundraisers/>}/>
    <Route path="/crowdfunding" element={<Crowdfunding/>}/>
    {/* <Route path="/support-our-village" element={<SupportOurVillage/>}/> */}
    <Route path="/gallery" element={<Gallery/>}/>
    <Route path="/contact-us" element={<ContactUs/>}/>
    {/* <Route path="/Events" element={<Events/>}/> */}
    <Route path="/donate-now" element={<DonateNowForm/>}/>
    <Route path="/help-seekers" element={<HelpSeekerForm/>}/>
    <Route path="/volunteers-application" element={<VolunteersApplication/>}/>

    <Route path="/be-a-volunteer" element={<BeVolunteer/>}/>
    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
    {/* <Route path="/refund-and-cancellation-policy" element={<RefundAndCancellationPolicy/>}/> */}
    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
    <Route path="/event-one" element={<EventsOne/>}/>
    <Route path="/event-two" element={<EventsTwo/>}/>
    <Route path="/event-three" element={<EventsThree/>}/>
    <Route path="/event-four" element={<EventsFour/>}/>
    <Route path="/event-five" element={<EventsFive/>}/>
    <Route path="/event-six" element={<EventsSix/>}/>
    <Route path="/event-seven" element={<EventsSeven/>}/>
    <Route path="/event-eight" element={<EventsEight/>}/>
    <Route path="/event-nine" element={<EventsNine/>}/>
    <Route path="/event-ten" element={<EventsTen/>}/>
    <Route path="/event-eleven" element={<EventsEleven/>}/>
    <Route path="/event-tweleve" element={<EventsTwelve/>}/>
    <Route path="/event-thirteen" element={<EventsThirteen/>}/>
    <Route path="/event-fourteen" element={<EventsFourteen/>}/>
    <Route path="/event-fifteen" element={<EventsFifteen/>}/>
    <Route path="/event-sixteen" element={<EventsSixteen/>}/>
    <Route path="/event-seventeen" element={<EventsSeventeen/>}/>
    <Route path="/food_donation_event" element={<FoodDonationEvent/>}/>
    <Route path="/medical_kits_event" element={<MedicalKitEvent/>}/>
    <Route path="/akoda_event_one" element={<AkodaEventOne/>}/>
    <Route path="/old-age-food-event" element={<Oldagefoodevent/>} />

    <Route path="Sign-in" element={<SignIn/>}/>
    <Route path="careers" element={<Careers/>}/>

    </Routes>
    <Subscribe/>
    <Footer/>
    </Router>
    

  );
}

export default App;
